<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Aprovação de jornada</span>
        <br />
        <small>Selecione abaixo a opção desejada</small>
      </div>
      <div class="modal__header__right">
        <v-icon @click="$emit('close')">$close_rounded</v-icon>
      </div>
    </div>

    <div v-if="!loadingInfo" class="modal__body">
      <div class="modal__body__journey">
        <TreeComponent v-if="tree != null && !loadingInfo" :nodes="tree" />
        <div v-else><span>Dados não encontrados</span></div>
      </div>

      <div v-if="isToReject" class="modal__body__form">
        <v-form ref="form">
          <z-input-area
            v-model="rejectComment"
            :hideDetails="true"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            :rows="1"
            style="width: 100%"
            placeholder="Escreva o motivo"
          />
        </v-form>
      </div>
    </div>

    <div v-else class="modal__body d-flex align-center justify-center">
      <z-loading />
    </div>

    <div class="modal--actions">
      <div class="reject d-flex">
        <z-btn
          :text="rejectButtonText"
          :iconRight="true"
          :disabled="loadingInfo"
          color="red"
          primary
          style="color: white"
          icon="$close_rounded"
          @click="setItemToReject"
        />
        <z-btn
          v-if="isToReject"
          :disabled="loadingInfo"
          :iconRight="true"
          :is-loading="loadingReject"
          color="red"
          text="Rejeitar jornada"
          style="color: white"
          @click="confirmReject"
        />
      </div>

      <div v-if="!isToReject" class="approve">
        <z-btn
          :iconRight="true"
          :disabled="loadingInfo"
          :is-loading="loadingApprove"
          icon="$check"
          primary
          style="color: white"
          color="green"
          text="Aprovar e ativar jornada"
          @click="confirmApproved"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import ApiClient from "@/services/ApiClient";
import { buildTreeService } from "../utils";
import TreeComponent from "./fluxogram/ExampleList.vue";
export default {
  props: {
    journeyProp: { type: Object, default: null, required: true },
  },
  components: {
    TreeComponent,
  },
  data() {
    return {
      rejectComment: "",
      isToReject: false,
      loadingInfo: false,
      loadingReject: false,
      loadingApprove: false,
      http: new ApiClient(),
      templatesList: [],
      audiencesList: [],
      tree: null,
      databaseConfigId: null,
    };
  },

  computed: {
    rejectButtonText() {
      return this.isToReject ? "Cancelar" : "Rejeitar";
    },
  },

  watch: {
    isToReject(nv) {
      if (!nv) this.rejectComment = "";
    },
  },

  async created() {
    this.loadingInfo = true;
    await this.getTemplatesAndAudiencesList();
    await this.getCurrentJourney();
    this.loadingInfo = false;
  },
  provide() {
    return {
      databaseConfigId: computed(() => this.databaseConfigId),
      isToUpdateDiamond: null,
      clickedNodesList: [],
      currentClickedNodeId: null,
    };
  },
  methods: {
    setItemToReject() {
      this.isToReject = !this.isToReject;
    },

    async getTemplatesAndAudiencesList() {
      const PROMISES = [
        this.http.get("templatesConfiguration/list"),
        this.http.get("audience/list"),
      ];

      await Promise.all(PROMISES)
        .then((results) => {
          const [TEMPLATES, AUDIENCES] = results;

          this.templatesList = TEMPLATES;
          this.audiencesList = AUDIENCES;

          return;
        })
        .catch(() => {
          this.$toast.error("Error ao buscar templates e audiências!");
        });

      return;
    },

    async getCurrentJourney() {
      try {
        this.loadingInfo = true;
        const response = await this.http.get(`journey/findOne/${this.journeyProp.id}`);

        const obj = {
          status: response.journey.status,
          steps_array: response.steps,
          current_audience_id: response.journey.audienceId,
          audience_list: this.audiencesList,
          template_sms_email_arr: this.templatesList,
        };

        this.databaseConfigId = this.audiencesList.find(
          (d) => d.id === response.journey.audienceId
        )?.databaseConfigId;

        const { tree } = await buildTreeService(obj);

        this.tree = tree;

        this.loadingInfo = false;
      } catch (e) {
        this.loadingInfo = false;
        this.$toast.error("Erro ao buscar jornada atual");
      }
    },

    async confirmReject() {
      if (this.$refs.form.validate()) {
        try {
          this.loadingReject = true;

          await this.http.post("journey/rejectJourney", {
            id: this.journeyProp.id,
            rejectComment: this.rejectComment,
          });

          this.loadingReject = false;
          this.$toast.success("Jornada rejeitada!");
          this.$emit("update-and-close");
        } catch (error) {
          this.loadingReject = false;
          this.$toast.error("Erro ao rejeitar jornada.");
        }
      }
    },

    async confirmApproved() {
      try {
        this.loadingApprove = true;

        await this.http.post("journey/approveJourney", {
          id: this.journeyProp.id,
        });

        this.loadingApprove = false;
        this.$toast.success("Jornada aprovada!");
        this.$emit("update-and-close");
      } catch (error) {
        this.loadingApprove = false;
        this.$toast.error(error && error.body && error.body.detail ? error.body.detail : 'Erro ao aprovar jornada');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    flex: 1 1 100%;
    height: 100%;
    margin: $z-s-1 0;
    padding: 0.5rem;
    max-height: 65vh;
    overflow-y: auto;
    @include trackScrollBar;
    display: flex;
    flex-direction: column;
    gap: $z-s-1;

    &__journey {
      height: 40vh;
      border: 2px dashed $z-black-1;
      border-radius: 5px;
      overflow-y: auto;
      padding: $z-s-1;
      @include trackScrollBar;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    .reject {
      gap: 0.5rem;
    }
  }
}
</style>
