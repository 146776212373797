<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Escolha o comportamento para a jornada pausada:</span>
      </div>
      <div class="modal__header__right">
        <v-icon @click="$emit('close')">$close_rounded</v-icon>
      </div>
    </div>

    <div class="modal__body">
      <v-radio-group
        :rules="[(v) => !!v || 'Campo obrigatório']"
        v-model="pauseType"
        hide-details
        small
      >
        <v-radio style="width: 350px" value="keep" small>
          <template #label>
            <span style="color: #000000">Manter os envios na fila</span>
          </template>
        </v-radio>
        <v-radio style="width: 350px" value="remove">
          <template #label>
            <span style="color: #000000">Remover os envios da fila</span>
          </template>
        </v-radio>
      </v-radio-group>

      <z-btn
        :disabled="loadingInfo"
        :iconRight="true"
        :is-loading="loadingReject"
        color="red"
        text="Pausar jornada"
        style="color: white"
        @click="confirmPause"
      />
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";

export default {
  props: {
    journeyProp: { type: Object, default: null, required: true },
  },
  data() {
    return {
      pauseType: "keep",
      http: new ApiClient(),
    };
  },
  methods: {
    async confirmPause() {
      await this.http.post("journey/pauseJourney", {
        id: this.journeyProp.id,
        pauseBehaviour: this.pauseType,
      });
      this.$toast.success("Jornada pausada!");
      this.$emit("update-and-close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    flex: 1 1 100%;
    height: 100%;
    margin: $z-s-1 0;
    padding: 0.5rem;
    max-height: 65vh;
    overflow-y: auto;
    @include trackScrollBar;
    display: flex;
    flex-direction: column;
    gap: $z-s-1;

    &__journey {
      //   min-height: 20vh;
      border-radius: 5px;
      overflow-y: auto;
      @include trackScrollBar;
    }
  }
}
</style>
