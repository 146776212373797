<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Jornada reprovada por {{ journeyProp?.rejectedBy || '' }}:</span>
      </div>
      <div class="modal__header__right">
        <v-icon @click="$emit('close')">$close_rounded</v-icon>
      </div>
    </div>

    <div class="modal__body">
      <div class="modal__body__journey">
        <p>
          {{ journeyProp?.rejectComment || "Sem registro de reprovação cadastrado." }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    journeyProp: { type: Object, default: null, required: true },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    flex: 1 1 100%;
    height: 100%;
    margin: $z-s-1 0;
    padding: 0.5rem;
    max-height: 65vh;
    overflow-y: auto;
    @include trackScrollBar;
    display: flex;
    flex-direction: column;
    gap: $z-s-1;

    &__journey {
      //   min-height: 20vh;
      border-radius: 5px;
      overflow-y: auto;
      @include trackScrollBar;
    }
  }
}
</style>
