import { render, staticRenderFns } from "./createdJourneys.vue?vue&type=template&id=341f5292&scoped=true&"
import script from "./createdJourneys.vue?vue&type=script&lang=js&"
export * from "./createdJourneys.vue?vue&type=script&lang=js&"
import style0 from "./createdJourneys.vue?vue&type=style&index=0&id=341f5292&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "341f5292",
  null
  
)

export default component.exports